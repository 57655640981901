<template>
  <div id="simple-calendar-app">
    <div class="vx-card no-scroll-content">
      <calendar-view
        ref="calendar"
        :displayPeriodUom="calendarView"
        :show-date="showDate"
        :events="trials"
        enableDragDrop
        :eventTop="windowWidth <= 400 ? '2rem' : '3rem'"
        :locale="$i18n.locale"
        eventBorderHeight="0px"
        eventContentHeight="1.65rem"
        class="theme-default"
        @click-event="openCaseDetail"
      >
        <div slot="header" class="mb-4">
          <div class="vx-row no-gutter">
            <!-- Month Name -->
            <div class="vx-col w-1/3 items-center sm:flex hidden"></div>

            <!-- Current Month -->
            <div
              class="vx-col sm:w-1/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last"
            >
              <div class="flex items-center">
                <feather-icon
                  :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                  @click="updateMonth(-1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                />

                <span class="mx-3 text-xl font-medium whitespace-no-wrap">
                  {{ showDate | monthAndYear($i18n.locale) }}
                </span>

                <feather-icon
                  :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                  @click="updateMonth(1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                />
              </div>
            </div>

            <div class="vx-col sm:w-1/3 w-full flex justify-center">
              <template v-for="(view, index) in calendarViewTypes">
                <vs-button
                  :key="
                    String(view.val) +
                    (calendarView === view.val ? 'filled' : 'border')
                  "
                  :type="calendarView === view.val ? 'filled' : 'border'"
                  class="p-3 md:px-8 md:py-3"
                  :class="{
                    'border-l-0 rounded-l-none': index,
                    'rounded-r-none': calendarViewTypes.length !== index + 1,
                  }"
                  @click="calendarView = view.val"
                  >{{ $t(`calendar.types.${view.label}`) }}</vs-button
                >
              </template>
            </div>
          </div>
        </div>
      </calendar-view>
    </div>

    <!-- EDIT EVENT -->
    <vs-prompt
      class="calendar-event-dialog"
      :title="`${selectedCase.cid} ${selectedCase.customer.alias} - ${selectedCase.establishment.alias}`"
      :accept-text="$t('calendar.buttons.goToCase')"
      :cancel-text="$t('calendar.buttons.close')"
      button-cancel="border"
      @close="cancelDetail"
      @cancel="cancelDetail"
      @accept="goToCase"
      :active="true"
      v-if="!!selectedCase"
    >
      <div class="my-4">
        <small class="date-label">{{ $t("calendar.titles.day") }}</small>
        <div>
          {{
            $moment(selectedCase.trialDatetime.toDate()).format("DD/MM/YYYY")
          }}
        </div>
      </div>
      <div class="my-4">
        <small class="date-label">{{ $t("calendar.titles.hour") }}</small>
        <div>
          {{ $moment(selectedCase.trialDatetime.toDate()).format("HH:mm") }}
        </div>
      </div>
      <div class="my-4" v-if="!!selectedCase.court">
        <small class="date-label">{{ $t("calendar.titles.court") }}</small>
        <div>{{ selectedCase.court.alias }}</div>
      </div>
      <div
        class="my-4"
        v-if="!!selectedCaseWitnesses && selectedCaseWitnesses.length > 0"
      >
        <small class="date-label">{{ $t("calendar.titles.witnesses") }}</small>
        <div v-for="w in selectedCaseWitnesses" :key="w.code">
          {{ w.name }} {{ w.lastname }}
          <a :href="`tel:${w.mobile}`" v-if="w.mobile">
            - <feather-icon icon="PhoneIcon" svgClasses="w-4 h-4" />
            {{ w.mobile }}</a
          >
        </div>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
require("vue-simple-calendar/static/css/default.css");

import Datepicker from "vuejs-datepicker";
import moment from "moment";

import { db } from "@/firebase/firebaseConfig";

export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker,
  },
  filters: {
    monthAndYear: (val, locale) => {
      return moment(val).locale(locale).format("MMM YYYY");
    },
  },
  data() {
    return {
      showDate: new Date(),
      disabledFrom: false,
      selectedCase: null,
      selectedCaseWitnesses: null,

      calendarView: "month",

      calendarViewTypes: [
        {
          label: "month",
          val: "month",
        },
        {
          label: "week",
          val: "week",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "allCustomers",
      "allEstablishments",
      "allLawyers",
      "allCases",
    ]),
    isLawyer() {
      return this.$store.state.AppActiveUser.role === "lawyer";
    },
    isCustomer() {
      return this.$store.state.AppActiveUser.role === "customer";
    },
    isSuperCustomer() {
      return this.$store.state.AppActiveUser.role === "supercustomer";
    },
    processedCases() {
      return this.allCases.map((c) => {
        let processedCase = { id: c.id, ...c };

        if (!c.lawyer) {
          if (c.customer && c.establishment) {
            let customer = this.allCustomers.find(
              (x) => x.id === c.customer.id
            );
            let establishment = c.establishment.id
              ? this.allEstablishments.find((x) => x.id === c.establishment.id)
              : null;
            processedCase.lawyer =
              establishment && establishment.lawyer
                ? establishment.lawyer
                : customer.lawyer;
          }
        }
        return processedCase;
      });
    },
    trials() {
      return this.processedCases
        .filter((c) => c.trialDatetime != null)
        .map((c) => {
          let styles = [];
          if (c.lawyer) {
            let lawyer =
              this.allLawyers.find((l) => l.id === c.lawyer.id) || {};
            if (lawyer.color) {
              styles.push(`background-color: ${lawyer.color} !important`);
            }
          }
          if (c.courtPenal && c.courtPenal.id) {
            styles.push(`border: 2px inset black !important`);
            styles.push(`font-weight: bold`);
          }
          return {
            id: c.id,
            startDate: c.trialDatetime.toDate(),
            endDate: c.trialDatetime.toDate(),
            title: `${this.$moment(c.trialDatetime.toDate()).format(
              "HH:mm"
            )} - ${c.cid} ${c.customer.alias} - ${c.establishment.alias}`,
            classes: "event-primary",
            style: styles.join(";"),
          };
        });
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    async witnesses(caseId) {
      let snap = await db
        .collection("witnesses")
        .where("cases", "array-contains", caseId)
        .where("deleted", "==", false)
        .get();

      let witnesses = snap.docs
        ? snap.docs.filter((d) => d.exists).map((d) => d.data())
        : null;

      return witnesses;
    },
    updateMonth(val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val);
    },
    async openCaseDetail(event) {
      this.selectedCase = this.allCases.find((c) => c.id === event.id);
      this.selectedCaseWitnesses = await this.witnesses(event.id);
    },
    goToCase() {
      this.$router.push(`/main/cases/${this.selectedCase.id}`);
      this.selectedCase = null;
      this.selectedCaseWitnesses = null;
    },
    cancelDetail() {
      this.selectedCase = null;
      this.selectedCaseWitnesses = null;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/simple-calendar.scss";

.custom-calendar-loading {
  position: relative;
  width: 100%;
  height: 110px;

  .loading {
    top: calc(50% - 35px);
  }
}
</style>
